.modal-void {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(5px);
  animation: modal-void-animate-in 0.3s forwards;
  pointer-events: all;
}

@keyframes modal-void-animate-in {
  0% {
    background-color: rgba(0, 0, 0, 0);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.4);
  }
}

.modal {
  position: fixed;
  top: 70px;
  left: 60px;
  right: 60px;
  bottom: 60px;
  background-color: white;
  z-index: 100;
  display: flex;
  flex-direction: column;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  color: var(--dark);
  animation: fade-bounce 0.5s;
  overflow-y: auto;
  max-height: 80vh;
}

.modal-alert {
  top: 40vh;
  left: 30vw;
  right: 30vw;
  bottom: 40vh;
}

.modal__close {
  padding: 5px;
  margin: 5px;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  color: var(--dark);
}

.modal__content {
  flex-grow: 1;
  overflow-y: auto;
  padding: 0px 40px;
}

@media only screen and (max-width: 900px) {
  .modal {
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: calc(var(--nav-height) + 20px);
  }

  .modal__content {
    flex-grow: 1;
    overflow-y: auto;
    padding: 0px 10px;
  }
}

.modal__unopinionated-size {
  top: unset;
  left: unset;
  right: unset;
  bottom: unset;
}

.confirm-delete__cancel {
  background-color: var(--piano);
}

.confirm-delete__confirm {
  background-color: var(--brand-color-red);
}