.year-slider {
    position: fixed;
    left: calc( 160px );
    right: calc( var(--bento-element-container-width__standard) + 5px );
    top: calc( var(--nav-height) + 4px);
    background-color: white;
    padding: 10px 30px;
    animation: fade-in 0.4s;
    border-top: 1px dashed var(--pianissimo);
    border-right: 1px dashed var(--pianissimo);
    border-radius: 0 0 20px 0;
    z-index: 11;
}

@media only screen and (max-width: 700px) {
    .year-slider {
        left: 0;
        right: 0;
        top: 0;
        border-radius: 0 0 20px 20px;
    }
}

.year-slider input[type="range"] {
    width: 100%;
}

.year-slider .delineations {
    position: relative;
    width: 100%;
    height: 20px;
    margin-top: 10px;
}

.year-slider .delineations span {
    position: absolute;
    transform: translateX(-50%);
    font-size: 10px;
    white-space: nowrap;
}

.icon-container__app-controller-year-slider__closer {
    position: absolute;

    top: 15px;
    right: 15px;
    cursor: pointer;
}