




.item-type-illustration {

}

.item-type-illustration__type {
    background-color: var(--pianississimo);
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.item-type-illustration__type:hover {
    background-color: var(--pianissimo);
}

.item-type-illustration__type:last-child {
    margin-bottom: 0px;
}

.item-type-illustration__type-name__outer {
    max-width: 80px;
    border: none;
}

.item-type-illustration__type-name__outer p {
    font-size: 12px;
}

.item-type-illustration__type-name {
    width: 50px;
    text-align: center;
    padding: 2px 8px 4px 8px;
    color: var(--mezzo-pianissimo);
    background-color: var(--piano);
    color: white;
    border-radius: 5px;
    font-size: 12px;
}

.item-type-illustration__type-name__selected {
    background-color: var(--brand-color-green__alt);
}

.item-type-illustration__type-drawing {
    display: flex;
    min-height: 50px;
    flex-grow: 1;
    align-items: center;
    justify-content: flex-end;
}

.item-type-illustration__type-drawing .number-circle {
    margin-right: 10px;
}

.item-type-illustration__type-drawing__line {
    height: 2px;
    width: 60px;
    background-color: var(--brand-color-green__alt);
    border-radius: 5px;
}

.item-type-illustration__type-drawing__polygon {
    width: 80px;
    height: 80px;
}