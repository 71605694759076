.search {
    display: inline-flex;
    border: 1px solid var(--pianissimo);
    border-radius: 5px;
    margin-left: 10px;
    align-items: center;
    height: 36px;
}

.search .adv-input input {
    border: none;
    width: unset;
    background-color: inherit;
}

.search .adv-input input::placeholder {
    color: var(--piano);
}

.icon-container__search__icon {
    margin-left: 10px;
    margin-right: 5px;
}

.search .popover-children-container {
    min-height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.search__date-settings-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    cursor: pointer;
}

.search__filters-modal__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.search__filters-modal {
    width: 400px;
}

.search-within-dates-modal {
    min-height: 500px;
}