.app-controller__horizontal-flex {
    display: flex;
}

.app-controller__vertical-flex {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: 100vh;
    max-height: 100vh;
}

@media only screen and (max-width: 1073px) {
    .app-controller__vertical-flex {
        width: 100px;
        height: 100dvh;
        max-height: 100dvh;
    }
}

.search-and-borders {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    padding-top: 7px;
    padding-bottom: 7px;
    background-color: white;
}

.search-and-borders__group-1 {
    display: flex;
    justify-content: flex-start;
}

.search-and-borders__group-2 {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
}

@media only screen and (max-width: 1073px) {
    .search-and-borders {
        height: 50px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        max-height: unset;
        overflow-x: auto;
    }
    .search-and-borders__group-1 {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .app-controller__quick-usage-note {
        max-width: 250px;
    }
}

.search-and-borders__logo {
    user-select: none;
    margin-left: 13px;
    margin-right: 8px;
    margin-bottom: 2px;
}

@media only screen and (max-width: 1073px) {
    .search-and-borders__logo {
        margin-right: 10px;
    }
}

.ad-section__horizontal {
    display: none;
    height: 90px;
    background-color: var(--mezzo);
}

@media only screen and (max-width: 1073px) {
    .ad-section__horizontal {
        display: block;
    }
}

.ad-section__vertical {
    width: 160px;
    min-width: 160px;
    background-color: var(--mezzo);
}

@media only screen and (max-width: 1073px) {
    .ad-section__vertical {
        display: none;
    }
}

.app-controller__map-and-sidebar {
    height: 100vh;
    max-height: 100vh;
    display: flex;
}

@media only screen and (max-width: 1073px) {
    .app-controller__map-and-sidebar {
        height: calc(100vh - 150px);
        max-height: calc(100vh - 150px);
    }
}

.icon-container__small-screen-search-and-borders-opener {
    cursor: pointer;
    margin-right: 20px;
}

.modal__unopinionated-size.small-screen-search-and-borders-modal {
    bottom: 40px;
}

.small-screen-search-and-borders-container__wrapper {
    display: flex;
    justify-content: space-between;
}

.small-screen-search-and-borders-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}

.small-screen-search-and-borders-container .search {
    margin-top: 20px;
    padding: 0;
    margin: 0;
    width: 100%;
}

.small-screen-search-and-borders-container .border-date-controller__outer {
    margin-top: 20px;
    padding: 0;
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}


.small-screen-search-and-borders-container .app-controller__toolbar {
    padding: 0;
    margin: 0;
    width: 100%;
}

.small-screen-search-and-borders-container .app-controller__quick-usage-note {
    padding: 0;
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.small-screen-search-and-borders-container .border-date-controller__outer .border-date-controller {
    margin-left: 0;
}

.small-screen-search-and-borders-container .search,
.small-screen-search-and-borders-container .border-date-controller__outer,
.small-screen-search-and-borders-container .app-controller__toolbar,
.small-screen-search-and-borders-container .app-controller__quick-usage-note
{
    margin-bottom: 20px;
}

.app-controller-temp-beta-div {
    position: fixed;
    bottom: 40px;
    right: 15px;
    text-align: right;
    color: white;
    font-weight: 700;
    background-color: var(--brand-color-green__alt);
    border-radius: 10px;
    font-size: 10px;
    padding: 10px;
    text-decoration: none;
    z-index: 11;
}


@media only screen and (max-width: 1073px) {
    .app-controller-temp-beta-div {
        top: 10px;
        bottom: unset;
        right: 5px;
    }
}

.app-controller-temp-beta-inner {
    position: absolute;
    min-width: 110px;
    text-align: center;
    font-weight: 500;
    top: calc(100% - 5px);
    right: 10px;
    color: white;
    background-color: var(--brand-color-purple);
    border-radius: 10px;
    font-size: 10px;
    padding: 2px;
}