.item-and-story-view-principal {
    max-width: var(--bento-element-width__standard);
}

.item-and-story-view-principal > h2 {
    margin-bottom: 0;
}

.item-and-story-view-principal > p {
    margin-top: 5px;
}

.item-and-story-view-principal__items-in-this-story-heading {
    display: flex;
    align-items: center;
}

.item-and-story-view-principal__items-in-this-story-heading .tag {
    margin-left: 10px;
}

.item-and-story-view-principal .adv-accordion {
    margin-top: 10px;
    margin-bottom: 10px;
}

.item-and-story-view-principal__top-level-button {
    margin-bottom: 10px;
}

.item-and-story-view-principal__separator {
    margin-top: 20px;
    margin-bottom: 15px;
}