.separator {
  display: flex;
}

.separator__divider {
  height: 1px;
  background-color: var(--pianissimo);
  width: 100%;
  min-width: 100px;
  flex-grow: 1;
  margin-top: 0.6em;
}

.separator__text {
  margin: 0 10px 0 10px;
}

.adv-separator-simple {
  height: 1px;
  background-color: var(--pianissimo);
  width: 100%;
  margin: 5px 0;
}