.tag {
    background: var(--piano);
    color: var(--light-grey);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 4px 6px;
    border-radius: var(--border-radius);

    user-select: none;
}

.tag__clickable {
    cursor: pointer;
}