.app-controller-logger {

}

.app-controller-logger-output {
    color: var(--brand-color-green__alt);
}

.app-controller-logger-output__error {
    color: var(--brand-color-red);
}