.byline {
    display: flex;
    align-items: center;
}

.byline-descriptive-text-and-username {
    display: flex;
    flex-direction: column;
}

.byline-descriptive-text-and-username {
    margin-left: 10px;
}

.byline-descriptive-text-and-username__descriptive-text {
    font-size: 12px;
}

.byline-descriptive-text-and-username__username {
    font-size: 13px;
    font-weight: bold;
}
