.loading-container {
  position: relative;
  margin: 5px;
}

.loader {
  position: absolute;
  top: -7px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  border-top: 2px solid #FFF;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  margin-left: 10px;
}

.loading-container__larger .loader {
  width: 55px;
  height: 55px;
}

.loading-container__central {
  position: fixed;
  top: 50vh;
  left: 50vw;
}

.loading-container__larger.loading-container__central {
  width: 55px;
  height: 55px;
  top: calc(50vh - 25px);
  left: calc(50vw - 42px);
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}