.border-date-controller__outer {
    display: flex;
    min-height: 40px;
}



@media only screen and (max-width: 1073px) {
    .border-date-controller__outer {
        margin-right: 20px;
    }
}

.border-date-controller__outer .popover-top-level-container {
    display: flex;
}

.border-date-controller__outer .popover-activator-child-container {
    display: flex;
}

.border-date-controller__outer .popover-children-container  {
    background-color: var(--brand-color-green__alt);
    border: none;
}

.border-date-controller {
    display: flex;
    justify-content: center;
    margin-left: 5px;
    position: relative;


    border: 1px solid var(--pianissimo);
    border-radius: 5px;
    height: 32px;
    padding: 3px 2px 3px 7px;
}

.border-date-controller .icon-container {
    cursor: pointer;
}

.b-d-c__date-and-arrows {
    display: flex;
}

.b-d-c__date__container {
    display: flex;
}

.b-d-c__date {
    display: flex;
}

.b-d-c__date input.b-d-c-date__input__day  {
    width: 25px;
}

.b-d-c__date input.b-d-c-date__input__month  {
    width: 25px;
}

.b-d-c__date input {
    margin-left: 5px;
    border: none;
    outline: none;
    max-width: unset;
    width: 40px;
    display: flex;
    background-color: transparent;
    color: var(--mezzo);
    font-size: 13px;
}

.b-d-c__date input.date-input-error {
    color: var(--brand-color-red);
}

.b-d-c__date__switcher {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.b-d-c__date > label {
    user-select: none;
    position: absolute;
    font-size: 11px;
    top: -8px;
    z-index: 2;
    color: var(--piano);
    background-color: white;
    padding: 0px 2px;
    border: 1px solid var(--pianissimo);
    border-radius: 5px;
}

.b-d-c__date__switcher__day-label {
    left: 3px;
}

.b-d-c__date__switcher__month-label {
    left: 47px;
}

.b-d-c__date__switcher__year-label {
    left: 95px;
}

.b-d-c__arrows {
    display: flex;
    margin-left: 0px;
    margin-bottom: 2px;
}

.b-d-c__arrows > label {
    user-select: none;
    position: absolute;
    font-size: 11px;
    top: -8px;
    left: 162px;
    z-index: 2;
    color: var(--piano);
    background-color: white;
    padding: 0px 2px;
    border: 1px solid var(--pianissimo);
    border-radius: 5px;
}

.icon-container__b-d-c__arrows__right {
    padding-left: 5px;
}

.borders-info {
    user-select: none;
    color: white;
}