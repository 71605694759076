.app-controller__toolbar {
    border-radius: 5px;
    border: 1px solid var(--pianissimo);
    height: 32px;
    padding: 3px 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
    position: relative;
}

.app-controller__toolbar__info-label {
    user-select: none;
    position: absolute;
    font-size: 11px;
    top: -8px;
    left: 3px;
    z-index: 2;
    color: var(--piano);
    background-color: white;
    padding: 0px 6px;
    border: 1px solid var(--pianissimo);
    border-radius: 100px;
}

.popover-top-level-container__app-controller__toolbar__tool-container {
    padding-left: 3px;
    padding-right: 3px;
}

.popover-top-level-container__app-controller__toolbar__tool-container .popover-children-container {
    background-color: var(--brand-color-green__alt);
    color: white;
    border: none;
}

.popover-top-level-container__app-controller__toolbar__tool-container .icon-container {
    cursor: pointer;
}



@media only screen and (max-width: 700px) {
    .app-controller__toolbar .popover-container {
        display: none;
    }
}