.popover-top-level-container {
  --popover-width: 200px;
  --popover-height: 150px;
  --popover-padding: 30px;
  position: relative;
}

.popover-closer-void {
  position: fixed;
  z-index: 11;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.popover-container {
  cursor: pointer;
  z-index: 12;
  padding: var(--popover-padding);
  position: absolute;
  border-radius: var(--border-radius);
  animation: rotate-fade-in 0.5s;
  top: calc(75%);
  width: calc(100% - (2 * var(--popover-padding)));
  -webkit-animation: fade-drop-in 0.3s forwards;
  -o-animation: fade-drop-in 0.3s forwards;
  animation: fade-drop-in 0.3s forwards;
}

.popover-top-level-container__hide-popover .popover-container {
  display: none;
}

.popover-children-container {
  min-width: 200px;
  background-color: white;
  box-shadow: var(--box-shadow);
  border: 1px solid var(--pianissimo);
  border-radius: var(--border-radius);
  min-height: unset;
  max-height: 100px;
  overflow-y: auto;
  padding: 10px;
}

.popover-container__no-min-height {
  min-height: unset;
}

.popover-container__bottom-left {
  width: var(--popover-width);
  right: 10px;
}

.popover-container__bottom-right {
  width: var(--popover-width);
  left: -40px;
}

.popover-container__bottom {
  width: var(--popover-width);
  right: -5px;
}

.popover-container__top-left {
  right: calc(100% + 20px);
  min-width: 150px;
  top: calc(
    (-1.1 * var(--popover-height))
  );
  bottom: unset;
}

.popover-container h2 {
  font-size: 12px;
}
