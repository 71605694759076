.story-creation-and-versioning__items-in-this-story-heading {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.story-creation-and-versioning__items-in-this-story-heading .tag {
    margin-left: 10px;
}

.story-creation-and-versioning__add-item {
    margin-top: 10px;
}

.story-creation-and-versioning__item-controls {
    display: flex;
}

.story-creation-and-versioning__item-controls .adv-button {
    width: unset;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 10px;
}

.story-creation-and-versioning__item-controls .adv-button:first-of-type {
    margin-left: 0px;
}

.item-card__expecting-edit-definition {
    padding: 10px;
    border-radius: var(--border-radius);
    border: 1px solid var(--pianissimo);
}

.story-creation-separator-before-about {
    margin-top: 30px;
    margin-bottom: 30px;
}