.item-card {
    position: relative;
    padding: 10px;
    border-radius: var(--border-radius);
    background-color: var(--pianississimo);
    margin-bottom: 10px;

    -webkit-animation: fade-drop-in 0.3s forwards;
    -o-animation: fade-drop-in 0.3s forwards;
    animation: fade-drop-in 0.3s forwards;
}

.item-card .item-card__quick-search {
    position: relative;
    color: var(--piano);
    z-index: 11;
}

.item-card h1, .item-card h2, .item-card p {
    margin: 0;
    margin-top: 10px;
}

.item-card h1 {
    font-size: 18px;
    user-select: text;
}

.item-card p {
    font-size: 14px;
    color: var(--piano);
    user-select: text;
}

.item-card__details__title-and-description {
    margin-bottom: 20px;
    max-width: 65%;
}

.item-card__date-container {
    margin-top: 13px;
    margin-bottom: 13px;
}

.item-card__date-container .item__creation__year .adv-input {
    width: 3.6em;
}
.item-card__date-container .item__date__datum__year {
    min-width: unset;
}

.item-card__controls {
}

.item-card__controls__icons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.item-card__controls__icons .icon-container {
    margin-left: 10px;
    cursor: pointer;
}

.item-card__map-polygon-container {
    position: absolute;
    right: 10px;
    top: 10px;
    transform: rotateZ(270deg);
}

.item-card-additional-section {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.item-card-additional-section > *:nth-child(n) {
    margin-left: 10px;
}

.item-card-chip-menu-container {
    display: flex;
    justify-content: flex-end;
}

.item-card .item-card-chip-menu-container {
    margin-top: 10px;
}

.item-card .item-card-chip-menu-container .popover-container__top-left {
    min-height: 200px;
}
.item-card .item-card-chip-menu-container .popover-children-container {
    min-height: 120px;
}