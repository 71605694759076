.app-controller-user {
    align-self: flex-end;
    justify-self: flex-end;
    margin-right: 10px;
    margin-left: 10px;
}

@media only screen and (max-width: 1073px) {
    .app-controller-user {
        margin-bottom: 10px;
        margin-right: 0;
    }
}

.app-controller-user__modal__contents {
    min-width: 250px;
    max-width: 300px;
}

.app-controller-user__separator {
    margin-top: 20px;
    margin-bottom: 20px;
}