.app-controller-displaying h1 {
    margin: 0;
}

.app-controller-displaying h2 {
    margin: 0;
    margin-top: 10px;
}


.app-controller-displaying__title-and-chip {
    display: flex;
    align-items: flex-end;
}

.app-controller-displaying__title-and-chip .chip {
    margin-left: 5px;
    margin-bottom: 2px;
    max-height: 25px;
    font-size: 12px;
}

.app-controller-displaying__title-and-chip .chip-inner {
    padding-left: 4px;
    padding-right: 4px;
    min-width: 8px;
}

.app-controller-displaying p {
    margin-top: 5px;
    margin-bottom: 5px;
}

.app-controller-displaying__note {
    margin-top: 0;
    font-size: 12px;
    color: var(--piano);
}

.app-controller-displaying .adv-accordion__revealed-block__expanded {
    max-height: 80vh;
}

@media only screen and (max-width: 1073px) {
    .app-controller-displaying .adv-accordion__revealed-block__expanded {
        max-height: 65vh;
    }
}