.chip {
  background-color: var(--pianissimo);
  color: var(--forte);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: none;
  transition: all 0.5s;
  user-select: none;
}

.chip.chip__disabled {
  background-color: var(--mezzo-pianissimo);
  cursor: not-allowed;
}

.chip-inner {
  padding: 8px 8px;
}

.chip:hover {
  box-shadow: var(--box-shadow);
}

.chip-menu-item__main {
  margin: 5px 0 0 0;
  padding: 5px;
  border-radius: var(--border-radius);
  transition: all 0.5s;
}

.chip-menu-item__main:hover {
  background-color: var(--pianississimo);
}

.chip-menu-item__main h1 {
  font-size: 14px;
  margin: 0 !important;
  padding: 0 !important;
}

.chip-menu-item__main p {
  font-size: 12px;
  margin: 0 !important;
  padding: 0 !important;
}