.adv-accordion {
    width: 100%;
}

.adv-accordion__always-visible {
    width: 100%;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    justify-content: flex-start;
    margin-bottom: 10px;
}

.adv-accordion__always-visible__main-text-and-icon {
    width: 100%;
    display: flex;
    cursor: pointer;
    justify-content: space-between;
    align-items: center;
}

.adv-accordion__always-visible__main-text {
    font-size: 18px;
    color: var(--mezzo);
}

.adv-accordion__always-visible__description {
    font-size: 14px;
    color: var(--piano);
}

.adv-accordion__always-visible__description p {
    margin: 2px 0 0 0;
}

.adv-accordion__always-visible__dropdown-icon-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.adv-accordion__always-visible .icon-container {
    margin-left: 10px;
    transform: rotateZ(0deg);
    transition: all 0.5s;
}

.adv-accordion__always-visible .icon-container__expanded {
    transform: rotateZ(180deg);
}

.adv-accordion__revealed-block {
    min-height: 0;
    max-height: 0;
    overflow-y: hidden;
    transition: all 0.5s;
}

.adv-accordion__revealed-block__expanded {
    margin-top: 10px;
    max-height: 1900px;
}

.adv-accordion__revealed-block__expanded__overflow-auto {
    overflow-y: auto;
}

.adv-accordion__revealed-block__expanded__overflow-visible {
    overflow-y: visible;
}