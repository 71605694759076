.item-creation {
    padding: 0 10px;
}

.item__creation__outer {
    background-color: var(--pianississimo);
    width: var(--bento-element-width__standard);
    border-radius: var(--border-radius);
}

.item__creation h2 {
    margin-top: 0;
    padding-top: 0;
    text-align: center;
}

.item__creation h3 {
    font-size: 13px;
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 5px;
}

.item__creation {}

.item__creation__spacer {
    border-top: 1px dotted var(--pianissimo);
    margin-top: 20px;
    height: 20px;
}

.item__creation__small-textual-setting {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: var(--mezzo);
    cursor: pointer;
}

.item__creation__small-textual-setting .icon-container {
    margin-right: 5px;
}


.item__creation .popover-children-container {
    max-height: unset;
}

.adv-item-creation-generic-error {
    margin-top: 20px;
    width: calc(100% - 20px);
    color: white;
    background-color: var(--brand-color-red);
    padding: 10px;
    border: 1px solid var(--mezzo);
    border-radius: var(--border-radius);
    font-size: 12px;
}

.adv-item-creation-generic-error > * {
    display: inline;
}

.adv-item-creation-generic-error > .icon-container {
    position: relative;
    top: 5px;
}

.item__story-association__container .popover-activator-child-container h3 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.item__story-association__container .popover-activator-child-container .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
    cursor: pointer;
}


.item__story-association__container .popover-children-container {
    max-height: 200px;
}

.item__creation__draw-reminder {
    margin-top: 30px;
    font-style: italic;
    color: var(--brand-color-purple);
}