.story-card {
    position: relative;
    padding: 10px;
    border-radius: var(--border-radius);
    background-color: var(--pianississimo);
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    -webkit-animation: fade-drop-in 0.3s forwards;
    -o-animation: fade-drop-in 0.3s forwards;
    animation: fade-drop-in 0.3s forwards;
}

.story-card h1 {
    font-size: 18px;
}

.story-card p {
    font-size: 14px;
    color: var(--piano);
}

.story-card__details__title-and-description {
    width: 100%;
    max-width: 100%;

    -webkit-animation: fade-drop-in 0.3s forwards;
    -o-animation: fade-drop-in 0.3s forwards;
    animation: fade-drop-in 0.3s forwards;
}

.story-card__byline-and-controls {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
}

.story-card__byline-container {
    display: flex;
    justify-content: flex-start;

}

.story-card__controls-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-end;
    justify-content: flex-end;
}

.story-card__byline-and-controls .simple-small-button {
    max-width: 100px;
}

.story-card__edit-button-container {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.story-card__show-button-container {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-end;
}

.story-card__show-button-container .adv-button:nth-child(2) {
    margin-top: 10px;
}