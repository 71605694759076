.app-controller__quick-usage-note {
    user-select: none;
    border-radius: 5px;
    border: 1px solid var(--pianissimo);
    width: 240px;
    height: 32px;
    padding: 3px 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
    position: relative;
}

@media only screen and (max-width: 1073px) {
    .app-controller__quick-usage-note {
        width: 300px;
        min-width: 300px;
        margin-right: 10px;
    }
}

.app-controller__quick-usage-note label {
    color: var(--piano);
    font-size: 11px;
}

.app-controller__quick-usage-note .in-animation {
    animation: fade-in 1s forwards;
}

.app-controller__quick-usage-note .out-animation {
    animation: fade-out 1s forwards;
}

.app-controller__quick-usage-note__info-label {
    user-select: none;
    position: absolute;
    font-size: 11px;
    top: -8px;
    right: 3px;
    z-index: 2;
    color: var(--piano);
    background-color: white;
    padding: 0px 6px;
    border: 1px solid var(--pianissimo);
    border-radius: 100px;
}