.user-details__update-profile-image-button {
    margin-bottom: 10px;
}

.avatar-container__user-details__update-profile-image__profile-image {
    margin-bottom: 10px;
}

.user-details__save-button__container {
    margin-top: 20px;
    margin-bottom: 40px;
}

.user-details__form .adv-input-outer {
    margin-top: 10px;
}

.user-details__logo-settings .avatar-container {
    margin-top: 10px;
}