.item__story-association {
}

.item__story-association__align-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.item__story-association__create-new-story {
    margin-top: 20px;
}