.user-assets-container {
    display: block;
}

.user-assets {
    display: flex;
    flex-wrap: wrap;
}

.image-asset-container {
    margin-right: 18px;
    margin-bottom: 18px;
    position: relative;
    display: inline-flex;
    flex-wrap: wrap;
    height: 125px;
    width: 125px;
}

.image-asset__upload-more {
    margin-bottom: 15px;
    height: 125px;
    width: 125px;
    background-color: var(--pianississimo);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    cursor: pointer;
}

.image-asset-container__image {
    border-radius: 20px;
    height: 125px;
    width: 125px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    border: 1px solid transparent;
}

.popover-top-level-container__asset-selection__image-asset__additional-menu {
    position: absolute;
    bottom: 25px;
    left: calc(100% - 25px);
    cursor: pointer;
}

.image-asset-container__image__selected {
    border: 1px solid var(--piano);
}