.secret-story-book__container {
    display: flex;
    justify-content: center;
    width: 100%;
}

.secret-story-book {
    border-radius: var(--border-radius);
    max-width: 900px;
    width: 100%;
    padding: 20px;
    background-color: white;
    border: 1px solid var(--pianississimo);
    background-color: var(--light-grey);
}

.secret-story-book>h1 {
    text-align: center;
    border-radius: var(--border-radius);
    background-color: var(--pianissimo);
    color: var(--mezzo);
    margin: 40px 0 20px;
    padding: 5px 10px;
}

.secret-story-book>h2 {
    margin: 40px 0 20px;
    text-align: center;
    border-radius: var(--border-radius);
    background-color: var(--mezzo-pianissimo);
    color: var(--light-grey);
    padding: 5px 10px;
}

.secret-story-block-index-links {
    display: flex;
    flex-wrap: wrap;
}

.secret-story-book__component-link {
    margin-right: 10px;
    margin-bottom: 10px;
}

.secret-story-book__component-link__button__attention {
    background-color: var(--warning);
}

.selected-component {
    margin-top: 50px;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
}

.selected-component-configurations {
    display: flex;
    flex-wrap: wrap;
}

.selected-component-configurations .adv-button {
    margin-top: 10px;
}

.selected-component-config__props-block {
    margin-top: 10px;
}

.selected-component-config {
    margin-top: 10px;
    margin-right: 10px;
    padding: 10px;
    background-color: var(--pianississimo);
    width: 200px;
    height: 300px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: var(--border-radius);
}

.secret-story-book .histochart-map-container {
    min-height: 800px;
    min-width: 800px;
}