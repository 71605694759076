.mode-selector__container {
  user-select: none;
}

.mode-selector {
  color: var(--mezzo);
  font-size: 12px;
  font-weight: bold;
  display: inline-flex;
  border-radius: var(--border-radius);
  background-color: var(--pianissimo);
  width: 100%;
}

.mode-selector__mode {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  cursor: pointer;
  padding: 8px 15px;
  transition: all 0.4s;
  margin: 5px;
  border-radius: 15px;
}

.mode-selector__mode:hover {
  border-radius: var(--border-radius);
  background-color: var(--pianississimo);
  color: var(--forte);
}

.mode-selector__mode__selected, .mode-selector__mode__selected:hover {
  border-radius: var(--border-radius);
  color: white;
  background-color: var(--brand-color-green__alt);
}
