.image-upload-container {
    background-color: var(--mezzo);
    min-height: 90px;
    min-width: calc(100% - 40px);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 90px 20px;
}

.image-upload__file-upload_custom-label, button[type=submit] {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 1px solid transparent;
    border-radius: var(--border-radius);
    min-width: 122px;
    padding: 0px 15px;
    transition: all 0.3s;
    text-decoration: none;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    min-height: 40px;
    background-color: var(--mezzo);
    user-select: none;
    text-decoration: underline;

    color: var(--pianississimo, #FFF);
    border-radius: 400px;
}

button[type=submit] {
    margin-top: 20px;
}

.image-upload__file-upload_custom-label {
    margin-top: 20px;
    margin-bottom: 20px;
}

input[type=file] {
    display: none;
}

.image-upload-container__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.image-upload-container__form button {
    background-color: var(--forte);
    border-radius: 5px;
    text-decoration: none;
}

.image-upload__preview-images {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
}

.image-upload__preview-image {
    max-height: 50px;
    max-width: 50px;
    border-radius: 3px;
}

.icon-container__image-upload__add-image-icon {
    margin-bottom: 10px;
}

.image-upload__clear-images {
    margin-top: 20px;
}