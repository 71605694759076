.vote-story {
    position: absolute;
    right: 10px;
    display: flex;
    z-index: 7;
}

.vote-story .icon-container {
    cursor: pointer;
}

.vote-story .icon-container:first-of-type {
    margin-right: 5px;
}

.vote-story .loading-container:first-of-type {
    margin-right: 8px;
}

.vote-story .loading-container:nth-of-type(2) {
    margin-right: 5px;
}

.vote-story .loader {
    top: 0;
    position: relative;
    margin: 0;
}

.vote-story .loader__dot {
    top: 0;
    position: relative;
}