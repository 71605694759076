.gm-style iframe + div { border:none!important; }

.histochart-map-container-inner {
    flex-grow: 1;
    width: 100% !important;
}

.histochart-map-container {
    width: 100%;
    display: flex;
    flex-grow: 1;
    min-height: 300px;
}

.histochart-map__drawing-mode .gm-style * {
    cursor: crosshair !important;
}
