.number-circle__outermost {
    position: relative;
    user-select: none;
}

.number-circle__outermost__map-style {
    position: absolute;
}

.number-circle__outer {
    position: relative;
}

.number-circle__outer__map-style {
    left: -50%;
    bottom: 50%;
}

.number-circle {
    display: flex;
    flex-wrap: wrap;
    background-color: grey;
}

.region .number-circle__tag {
    background-color: #333;
    color: white;
}

.region .number-circle__tag__border-style {
    background-color: transparent;
}

.number-circle__1-digit {

}

.number-circle__border {
    position: absolute;
    background-color: transparent;
    border-radius: 50%;
}

.number-circle__tag {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    text-align: center;
    border-radius: 5px;
    background-color: white;
    border: 1px solid black;
    width: 650%;
    left: -310%;
    padding: 3px;
}

.number-circle__tag__border-style {
    border-color: transparent;
}

.number-circle__tag__border-style .number-circle__tag__inner {
    font-size: 13px;
    word-break: break-word;
    text-shadow:
            -1px -1px 2px #fff,
            1px -1px 2px #fff,
            -1px 1px 2px #fff,
            1px 1px 2px #fff;
    font-weight: 800;
    color: black;
}

.cn-divider__vertical {
    position: absolute;
    background-color: black;
}

.cn-divider__horizontal {
    position: absolute;
    background-color: black;
}

.cn-section {
    height: 100%;
}

.cn-section__1-digit {
    width: 100%;
    flex-grow: 1;
}

.cn-section__2-digits {
    width: 50%;
}

.cn-section__3-digits {
    width: 50%;
    height: 50%;
}

.cn-section__3-digits:nth-child(3) {
    flex-grow: 1;
    width: 100%;
}

.cn-section__4-digits {
    width: 50%;
    height: 50%;
}