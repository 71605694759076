.sidebar__container {
    padding-left: 5px;
    width: var(--bento-element-container-width__standard);
    min-width: var(--bento-element-container-width__standard);
    max-width: var(--bento-element-container-width__standard);
    height: calc(100vh - (var(--search-and-borders-height)));
    max-height: calc(100vh - (var(--search-and-borders-height)));
    overflow-y: auto;
    border-top: 1px dashed var(--pianissimo);
}
@media only screen and (max-width: 1073px) {
    .sidebar__container {
        height: calc(100vh - 150px);
        max-height: calc(100vh - 150px);
    }
}


.sidebar__active-page {
    width: calc(100% - 12px);
    padding: 6px;

    -webkit-animation: fade-drop-in 0.3s forwards;
    -o-animation: fade-drop-in 0.3s forwards;
    animation: fade-drop-in 0.3s forwards;
}