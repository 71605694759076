.my-contributions__stories-container__inner > h1 {
    text-align: center;
    margin-top: 50px;
}

.my-contributions__stories-container__inner > p {
    color: var(--piano);
}

.my-contributions__stories-container {
    display: flex;
    justify-content: center;
}

.my-contributions__stories-container__inner {
    max-width: var(--bento-element-container-width__standard);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}