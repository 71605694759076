.item__date {
    display: flex;
    align-items: center;
    font-size: 14px;
}

.item__date__multiline {
    flex-direction: column;
    align-items: flex-start;
}

.item__date__date-groups {
    display: flex;
    align-items: center;
}

.item__date__date-groups__multiline {
    flex-direction: column;
    align-items: flex-start;
}

.item__date__start-date__group {
    position: relative;
    top: 0.5px;
    align-items: center;
    display: flex;
    align-items: flex-end;
}

.item__date__start-date {
    display: flex;
}

.item__date__date-groups > label {
    position: relative;
    bottom: 1px;
    width: 1.8em;
    text-align: center;
}

.item__date__multiline .item__date__date-groups > label {
    padding-top: 5px;
    padding-bottom: 12px;
}

.item__date__start-date__group > label {
    display: block;
    width: 3em;
}

.item__date__end-date__group {
    position: relative;
    top: 0.5px;
    align-items: center;
    display: flex;
}

.item__date__end-date__group > label {
    display: block;
    width: 3em;
}

.item__date__end-date {
    display: flex;
}

.item__date__datum {
    position: relative;
    border: 1px solid var(--pianissimo);
    border-right: none
}

.item__date__datum:first-child {
    border-radius: 5px 0 0 5px;
}

.item__date__datum:last-child {
    border-right: 1px solid var(--pianissimo);
    border-radius: 0 5px 5px 0;
}

.item__date .adv-input {
    margin: 0;
    padding: 0;
}

.item__date .adv-input input::placeholder {
    position: relative;
    left: -5px;
    text-align: center;
}

.item__date__datum input::placeholder {
    opacity: 0.5;
    text-align: left;
}

.item__date .adv-input input {
    background-color: transparent;
    margin-left: 5px;
    letter-spacing: 1px;
    padding: 2px 0px;
    border: none;
    text-align: left;
    width: 100%;
}

.item__date .adv-input {
    width: 2em;
}

.item__creation__month .adv-input {
    width: 2.2em;
}
.item__creation__year .adv-input {
    width: 100%;
    max-width: 4.6em;
}
.item__creation__year input::placeholder {
    padding-right: 13px;
}

.item__date__datum .adv-input__with-error:after {
    content: "";
    height: 2px;
    width: 100%;
    position: absolute;
    bottom: 0%;
    left: 0%;
    background-color: var(--brand-color-red);
}

.item__date__datum .adv-input-error {
    display: none;
}

.item__date__datum .adv-input-information {
    position: absolute;
    top: -12px;
    left: 0%;
    padding: 0;
    padding-left: 4px;
    border: none;
    background-color: transparent;
    font-size: 9px;
    color: var(--piano);
}

.item__date__datum .adv-input-information-2 {
    position: absolute;
    bottom: -12px;
    right: 5px;
    padding: 0;
    padding-left: 4px;
    border: none;
    background-color: transparent;
    font-size: 9px;
    color: #AEAEAE;
}

.tiny-control-button {
    width: unset;
    padding: 2px;
    margin: 0;
    min-height: unset;
    border-radius: 0;
    background-color: transparent;
    border-radius: 5px;
}

.item__date .popover-children-container {
    padding: 10px;
    background-color: var(--brand-color-green);
    font-size: 12px;
}

.item__date__tip {
    color: white;
}

.item__date__tip ul {
    padding-left: 25px;
}

.item__date__additional {
    display: flex;
    align-items: flex-end;
    margin-right: 5px;
}

.item__date__multiline .item__date__additional {
    padding-bottom: 20px;
}

.item__date__datum__to-present {
    color: var(--piano);
    position: relative;
    border: 1px solid var(--pianissimo);
    padding: 3px 8px;
    border-radius: 5px;
    font-size: 13px;
}

.item__date__datum__year {
    min-width: 80px;
}

.month-cheat-sheet {
    display: flex;
    flex-wrap: wrap;
}

.month-cheat-sheet__month {
    padding: 5px 8px;
    width: calc(50% - 16px);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.month-cheat-sheet__month:hover {
    text-decoration: underline;
}