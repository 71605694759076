.slider {
  user-select: none;
}

.slider-input {
  margin-right: 10px;
  width: 100%;
  cursor: grab;
}

.slider-input:active {
  cursor: grabbing;
}

.slider-disabled {
  opacity: 0.6;
  pointer-events: none;
}

.slider-value {
  font-size: 1rem;
}

.value-disabled {
  color: var(--piano);
}

.slider-description-and-value {
  display: flex;
  justify-content: space-between;
}

.slider input[type=number] {
  all: unset;
  padding: 5px;
  border: 1px solid var(--pianissimo);
  border-radius: var(--border-radius);
  max-width: 60px;
}

.slider-value-and-prefix {
  display: flex;
  align-items: center;
}

.slider-value-prefix {
  padding-right: 5px;
}