.adv-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  border-radius: var(--border-radius);
  width: 160px;
  padding: 0px 5px;
  transition: all 0.3s;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  min-height: 35px;
  color: white;
  background-color: var(--forte);
  user-select: none;
}

.adv-button.simple-small-button {
  background-color: var(--pianissimo);
  color: var(--forte);
  font-size: 12px;
  min-height: 30px;
}

@media only screen and (max-width: 700px) {
  .adv-button {
    width: unset;
    min-width: unset;
  }
}

.adv-button__full-width {
  width: 100%;
}

.adv-button__selected {
  box-shadow: none;
  background-color: var(--brand-color-blue__alt);
}

.adv-button:hover {
  box-shadow: var(--box-shadow-2);
}

.adv-button.simple-small-button:hover {
  box-shadow: var(--box-shadow);
}

.adv-button__disabled:hover {
  box-shadow: unset;
  background-color: var(--piano);
}

.adv-button-loading-state-loader {
  position: relative;
  margin-left: 10px;
  left: 2px;
  top: 2px;
}

.adv-button-loading-state-loader svg {
  stroke: white;
}

.adv-button__text {}

.adv-button__disabled {
  cursor: default;
  background-color: var(--piano);
}

.adv-button__with-icon {
  padding: 0;
  justify-content: space-between;
}

.adv-button__with-icon .adv-button__text {
  flex-grow: 1;
  text-align: center;
}

.adv-button__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--brand-color-green__alt);
  width: 40px;
  height: 38px;
}