.avatar-container {
    user-select: none;
    height: 34px;
    width: 34px;
    border-radius: 100px;
    border: 1px solid var(--pianissimo);
    cursor: pointer;
    box-shadow: none;
    transition: all 0.4s;
}

.avatar-container__large-size {
    height: 54px;
    width: 54px;
}

.avatar-container:hover {
    box-shadow: var(--box-shadow);
}

.avatar-container img {
    height: calc(100%);
    width: calc(100%);
    object-fit: cover;
    border-radius: 100px;
    background-size: contain;
}

.avatar-container svg {
    padding: 10px;
    height: calc(100% - 14px);
    width: calc(100% - 14px);
    max-height: calc(100% - 14px);
    max-width: calc(100% - 14px);
    border-radius: 100px;
    background-size: contain;
    background-color: white;
}

.avatar-container__large-size svg {
    height: calc(100% - 23px);
    width: calc(100% - 23px);
    max-height: calc(100% - 23px);
    max-width: calc(100% - 23px);
}