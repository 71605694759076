.auth-wrapper {
  padding-top: 50px;
  margin-left: calc(50% - 300px);
  min-height: 95vh;
  width: 600px;
  margin-bottom: 200px;
}

@media only screen and (max-width: 700px) {
  .auth-wrapper {
    margin-left: 0;
    margin-right: 0;
    padding: 30px 10px 10px 10px;
    width: calc(100% - 20px);
  }
}
