.country-label {
    text-shadow: -1px -1px 0 #FFF, 1px -1px 0 #FFF, -1px 1px 0 #FFF, 1px 1px 0 #FFF;
    font-weight: 700;
}

.country-label::before {
    content: "";
    position: absolute;
    top: calc(50% + 10px);
    left: calc(50% - 6px);
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: #3498db;
    border: 1px dashed black;
    margin-right: 5px;
    vertical-align: middle;
}

.country-label__0::before {
    background-color: #e6194B;
}

.country-label__1::before {
    background-color: #ff44b8;
}

.country-label__2::before {
    background-color: #7d1eb4;
}

.country-label__3::before {
    background-color: #3cb44b;
}

.country-label__4::before {
    background-color: #ffe119;
}

.country-label__5::before {
    background-color: #f58231;
}

.country-label__6::before {
    background-color: #911eb4;
}

.country-label__7::before {
    background-color: #42d4f4;
}

.country-label__8::before {
    background-color: #f032e6;
}

.country-label__9::before {
    background-color: #bfef45;
}

.country-label__10::before {
    background-color: #469990;
}

.country-label__11::before {
    background-color: #a03dff;
}

.country-label__12::before {
    background-color: #800000;
}